<template>
  <div class='text-center py-32 px-8 max-w-lg mx-auto'>
    <i class='el-icon-loading text-6xl mb-4'></i>
    <div class='font-semibold text-2xl mb-24'>{{registrationSuccessTitle}}</div>
    <div>{{registrationSuccessDescription}}</div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'IamportRegistrationSuccessMobile',
  data () {
    return {
      paymentSuccessTracker: null,
      waitingTimeForPaymentSuccess: 0
    }
  },
  watch: {
    'isCurrentOrderDone': {
      handler: function (newVal, oldVal) {
        if (newVal && newVal != oldVal) {
          if (this.isSingleOrder) {
            this.updateUserPaidData()
          } else {
            this.updateBatchPaidData()
          }
        }
      },
      immediate: true
    },
    'waitingTimeForPaymentSuccess': {
      handler: function (newVal) {
        if (newVal >= 2000) {
          clearInterval(this.paymentSuccessTracker)
          this.$alert(this.paymentFailMessage, {
            type: 'warning'
          }).then(() => {
            this.$router.push({name: 'IamportRegistrationFailMoblie', query: {order_id: this.paymentParams.orderId, single_order: this.$route.query.single_order, is_korean: this.$route.query.is_korean }})
          })
        }
      },
      immediate: true
    },
  },
  computed: {
    ...mapGetters('payments', [
      'isCurrentOrderDone',
    ]),
    ...mapGetters('events', [
      'showingEventId',
    ]),
    ...mapState('users', [
      'registeringAttendees'
    ]),
    isSingleOrder () {
      return this.$route.query.single_order === 'true'
    },
    isKorean () {
      return this.$route.query.is_korean === 'true'
    },
    paymentParams () {
      return { 
        pg:         this.$route.query.pg,
        orderId:    this.$route.query.order_id,
        // iamport m_redirect_url 에선 pg_tid 전달 안되기 때문에 backend에서 저장, merchant_uid는 40글자 제한이라 우리 파라미터를 씀
        paymentKey: 'iamport_mobile',
        impUid:     this.$route.query.imp_uid,
      }
    },
    iamportPaymentOrderParams () {
      return {
        event_id:    this.showingEventId,
        order_id:    this.paymentParams.orderId,
        payment_key: this.paymentParams.paymentKey,
        imp_uid:     this.paymentParams.impUid,
        single_order: this.isSingleOrder,
      }
    },
    paymentFailMessage () {
      return this.isKorean ? '결제가 정상적으로 처리되지 않았습니다.' : 'Your payment could not be processed.'
    },
    registrationSuccessTitle () {
      return this.isKorean? '결제가 진행중입니다.' : 'Your payment is currently being processed.'
    },
    registrationSuccessDescription () {
      return this.isKorean? '잠시만 기다려주세요. 결제 진행중에 앱을 닫으시거나 다른페이지로 이동하시면, 오류가 발생할 수 있습니다.' : 'Please do not refresh or navigate to another page. Doing so can cause the payment to fail.'
    },
  },
  methods: {
    ...mapActions('payments', [
      'checkPaymentStatus',
    ]),
    ...mapActions('iamportPayments', [
      'requestIamportPaymentOnSuccess',
    ]),
    ...mapActions('users', [
      'getRegisteringAttendeesByOrderId',
      'patchRegistrationData',
      'registrationDataBatchUpdate',
    ]),
    ...mapActions('registrations', [
      'sendRegistrationEmails',
    ]),
    updateUserPaidData () {
      this.patchRegistrationData({ payment: true, paidAt: new Date() }).then(() => {
        this.$router.push({name: 'IamportRegistrationFinishedMobile', query: {order_id: this.paymentParams.orderId, single_order: this.$route.query.single_order, is_korean: this.$route.query.is_korean }})
      })
    },
    updateBatchPaidData () {
      this.getRegisteringAttendeesByOrderId({orderId: this.paymentParams.orderId}).then(() => {
        let paidDataParams = {
          registrationsData: this.registeringAttendees.filter(attendee => attendee.registrationProduct.price.amount).map(attendee => {
            return {
              email: attendee.email,
              registrationData: {
                payment: true,
                paidAt: new Date(),
              }
            }
          })
        }
        this.registrationDataBatchUpdate(paidDataParams).then(() => {
          this.sendRegistrationEmails({ email_type: 'group_confirm', registering_attendees: this.registeringAttendees.map((attendee) => attendee.email) }).then(() => {
              this.$router.push({name: 'IamportRegistrationFinishedMobile', query: {order_id: this.paymentParams.orderId, single_order: this.$route.query.single_order, is_korean: this.$route.query.is_korean }})
          })
        })
      })
    },
  },
  mounted () {
    this.$emit('update:layout', 'empty-page')
    this.requestIamportPaymentOnSuccess(this.iamportPaymentOrderParams)
    this.paymentSuccessTracker = setInterval(() => {
      this.waitingTimeForPaymentSuccess += 500
      let orderId = ''
      if (this.$route.query.single_order === 'true') {
        orderId = this.paymentParams.orderId
      } else {
        orderId = this.paymentParams.orderId.split(',')[0]
      }
      this.checkPaymentStatus(orderId)
    }, 5000)
  },
  beforeDestroy () {
    this.$emit('update:layout', 'default-layout')
    clearInterval(this.paymentSuccessTracker)
  },
}
</script>
